
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ideaBoxApi from "@/core/services/IdeaBox";
import { Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
    name: "ideasdetail",
    components: { Form },
    data: function () {
        return {
            idea: {
                idea: "",
                category: this.$route.params.category_id,
                anonymous: false,
            },
            category: {
                label: "",
            },
        };
    },
    methods: {
        addIdea() {
            ideaBoxApi.addIdea(this.idea).then((response) => {
                this.getCategory();
                this.idea = {
                    idea: "",
                    category: this.$route.params.category_id,
                    anonymous: false,
                };
            });
        },
        getCategory() {
            ideaBoxApi.getCategory(this.$route.params.category_id).then((response) => {
                this.category = response.data["category"];
                console.log(this.category);
                setCurrentPageBreadcrumbs(this.category.label, [
                    {
                        link: "/ideas/",
                        label: this.t("pages.ideas.title"),
                    },
                ]);
            });
        },
        deleteIdea(idea) {
            ideaBoxApi.deleteIdea(idea.pk).then(() => {
                location.reload();
            });
        },
    },
    setup() {
        const { t } = useI18n();
        const store = useStore();

        console.log("ID user :", store);

        return { t };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.category.label, [
                {
                    link: "/ideas/",
                    label: this.t("pages.ideas.title"),
                },
            ]);
        },
    },
    created() {
        this.getCategory();
    },
});

<template>
    <div class="row">
        <div class="col-12">
            <div class="card mb-5 mb-xxl-8">
                <div class="card-body pb-0">
                    <Form id="kt_add_action_form" class="form" novalidate="novalidate">
                        <div class="row mb-6">
                            <!--begin::Label-->
                            <label class="col-lg-12 col-form-label required fw-bold fs-6">{{ $t("pages.ideas.ideaDesc") }}</label>
                            <!--end::Label-->

                            <!--begin::Col-->
                            <div class="col-lg-12">
                                <!--begin::Row-->
                                <div class="row">
                                    <!--begin::Col-->
                                    <div class="col-lg-12 fv-row">
                                        <textarea
                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                            :placeholder="$t('pages.ideas.ideaDesc')"
                                            name="idea"
                                            v-model="this.idea.idea"
                                        ></textarea>
                                        <label class="form-check form-check-inline form-check-solid mt-5">
                                            <input class="form-check-input" name="anonymous" v-model="this.idea.anonymous" type="checkbox" />
                                            <span class="fw-bold ps-2 fs-6"> {{ $t("pages.ideas.anonymous") }} </span>
                                        </label>
                                    </div>
                                    <!--end::Col-->
                                </div>
                                <!--end::Row-->
                            </div>
                            <!--end::Col-->
                        </div>
                    </Form>
                </div>
                <div class="card-footer d-flex justify-content-end py-6 px-9">
                    <button type="reset" class="btn btn-white btn-active-light-primary me-2">{{ $t("general.cancel") }}</button>

                    <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" class="btn btn-primary" @click="addIdea">
                        <span class="indicator-label"> {{ $t("general.save") }} </span>
                        <span class="indicator-progress">
                            {{ $t("general.pleaseWait") }}
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-4 mb-4" v-for="idea in this.category.ideas" v-bind:key="idea.pk">
            <div class="card bg-success bg-opacity-25 h-100">
                <div class="card-header flex-nowrap border-0 pt-9">
                    <div class="card-title w-100 d-block">
                        <div class="row">
                            <div class="col-10">
                                <i class="fas fa-lightbulb fs-3 me-3 text-gray-900"></i>
                                <a v-if="idea.anonymous" href="#" class="fs-4 fw-bold text-hover-primary text-gray-900 m-0">Anonyme</a>
                                <a v-else href="#" class="fs-4 fw-bold text-hover-primary text-gray-900 m-0">{{ idea.userprofile.lastname }} {{ idea.userprofile.firstname }}</a
                                ><br />
                                <i>{{ $moment(idea.date_created).format("DD/MM/YYYY") }}</i>
                            </div>
                            <div class="col-2">
                                <a
                                    href="#"
                                    v-if="this.$store.getters.currentUser.user.userprofile.id === idea.userprofile.id"
                                    @click="deleteIdea(idea)"
                                    class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm d-flex align-item-end"
                                    ><span class="svg-icon svg-icon-3"
                                        ><svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                xmlns="http://www.w3.org/2000/svg"
                                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                                fill="black"
                                            ></path>
                                            <path
                                                xmlns="http://www.w3.org/2000/svg"
                                                opacity="0.5"
                                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                                fill="black"
                                            ></path>
                                            <path
                                                xmlns="http://www.w3.org/2000/svg"
                                                opacity="0.5"
                                                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                                fill="black"
                                            ></path></svg></span
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body d-flex flex-column pb-8">
                    <div class="d-flex align-items-center flex-wrap mb-5 fs-6">
                        <div class="text-success fw-bolder me-2">
                            {{ idea.idea }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
#card-content {
    display: flex;
    align-content: center;
    justify-content: space-between;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ideaBoxApi from "@/core/services/IdeaBox";
import { Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
    name: "ideasdetail",
    components: { Form },
    data: function () {
        return {
            idea: {
                idea: "",
                category: this.$route.params.category_id,
                anonymous: false,
            },
            category: {
                label: "",
            },
        };
    },
    methods: {
        addIdea() {
            ideaBoxApi.addIdea(this.idea).then((response) => {
                this.getCategory();
                this.idea = {
                    idea: "",
                    category: this.$route.params.category_id,
                    anonymous: false,
                };
            });
        },
        getCategory() {
            ideaBoxApi.getCategory(this.$route.params.category_id).then((response) => {
                this.category = response.data["category"];
                console.log(this.category);
                setCurrentPageBreadcrumbs(this.category.label, [
                    {
                        link: "/ideas/",
                        label: this.t("pages.ideas.title"),
                    },
                ]);
            });
        },
        deleteIdea(idea) {
            ideaBoxApi.deleteIdea(idea.pk).then(() => {
                location.reload();
            });
        },
    },
    setup() {
        const { t } = useI18n();
        const store = useStore();

        console.log("ID user :", store);

        return { t };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.category.label, [
                {
                    link: "/ideas/",
                    label: this.t("pages.ideas.title"),
                },
            ]);
        },
    },
    created() {
        this.getCategory();
    },
});
</script>
